import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class FeedbackService {

  constructor(
      protected http: HttpClient,
	  @Inject('API_URL') private apiContext: string) {
  }

  async save(message: string): Promise<void> {
    
    return firstValueFrom(this.http.post<void>(`${this.apiContext}/feedbacks`, { message: message }));
  }
}

  